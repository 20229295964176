.shopTheLook:hover > .innerShopCard{
    display: flex !important;
}
.swiper-button-prev, .swiper-button-next{
    background-color: white;
    padding: 20px !important;
    border-radius: 100%;
}
.swiper-button-prev:after,.swiper-button-next:after{
    color: black;
    font-size: 12px !important;
}
.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
form{
    border: 1px solid rgba(128, 128, 128, 0.452);
    border-radius: 5px;
}
button{
    border-radius: 5px;
}
a:hover{
    cursor: pointer;
}